var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.css()},[_c('OrgHeaderInfo',{attrs:{"heading":_vm.t('module.reviews.heading')}}),(_vm.showFilters)?_c('ReviewsFilters',{class:_vm.css('filters'),attrs:{"rating":_vm.filterState.rating,"product-type":_vm.filterState.productType,"on-change":_vm.onFilterChange,"on-reset":_vm.onFilterReset}}):_vm._e(),_c('div',{class:_vm.css('content')},[(_vm.isFilterLoading)?_c('AtomLoading',{attrs:{"floating":"","inline":""}}):_vm._e(),(_vm.isFiltersActive && !_vm.reviewList.length)?_c('div',{class:_vm.css('empty-state-with-filters')},[_c('AtomText',{attrs:{"type":_vm.AtomTextTypeEnum.SUBTITLE}},[_vm._v(" "+_vm._s(_vm.t("module.reviews.empty_state_with_filter_title"))+" ")]),_c('AtomText',{attrs:{"color":_vm.AtomTextColorEnum.STORMY}},[_vm._v(" "+_vm._s(_vm.t("module.reviews.empty_state_with_filter_message"))+" ")]),_c('AtomButton',{class:_vm.css('reset-cta'),attrs:{"on-click":_vm.onFilterReset,"data-test":"empty-state-reset-filter-cta"}},[_vm._v(" "+_vm._s(_vm.t("module.reviews.reset_filters"))+" ")])],1):[_c('div',{class:[
          _vm.css('list'),
          ( _obj = {}, _obj[_vm.css('list', 'filters')] = _vm.isRatingFilterActive, _obj ) ]},[(_vm.isLoadingList)?_c('AtomLoading',{attrs:{"floating":"","inline":""}}):_vm._e(),(_vm.reviewList.length)?[_vm._l((_vm.reviewList),function(review){return _c('MolFeedbackCard',{key:review.id,attrs:{"id":review.id,"data-test":"feedback","name":review.customer.name,"product-type":_vm.getProductTypeLabel(review.product.type),"rating":review.rating,"comment":review.comment,"created-at":review.createdAt,"action-label":_vm.t('module.reviews.show_details'),"on-click":function () { return _vm.openDetails(review); },"on-product-label-click":function () { return _vm.onHiddenFilterChange('productType', [review.product.type]); },"on-rating-label-click":function () { return _vm.onHiddenFilterChange('rating', [("" + (review.rating))]); }}})}),(_vm.paginationInfo)?_c('MolPagination',{class:_vm.css('pagination'),attrs:{"current-page":_vm.paginationInfo.currentPage,"last-page":_vm.paginationInfo.lastPage,"per-page":_vm.paginationInfo.perPage,"total":_vm.paginationInfo.total,"on-navigate":_vm.onPaginationChanged}}):_vm._e()]:[_c('div',{class:_vm.css('empty-state'),attrs:{"data-test":"empty-state"}},[_c('AtomText',{attrs:{"type":_vm.AtomTextTypeEnum.SUBTITLE}},[_vm._v(" "+_vm._s(_vm.t("module.reviews.empty_state_title"))+" ")]),_c('AtomText',{class:_vm.css('empty-state-message'),attrs:{"color":_vm.AtomTextColorEnum.STORMY}},[_vm._v(" "+_vm._s(_vm.t("module.reviews.empty_state_message"))+" ")])],1)]],2),(!_vm.isRatingFilterActive)?_c('div',{class:_vm.css('average')},[_c('OrgRatingAverage',{attrs:{"rating":_vm.ratingInfo,"title":_vm.t('module.reviews.average_title'),"subtitle":_vm.isFiltersActive
              ? _vm.t('module.reviews.average_subtitle_with_filters')
              : _vm.t('module.reviews.average_subtitle', { reviewsCounter: _vm.reviewsCounter }),"progress-message":_vm.reviewsCounter
              ? _vm.t('module.reviews.message', { maxRating: 5 })
              : _vm.t('module.reviews.average_empty_state'),"decimal-point":1,"on-rating-click":function (rating) { return _vm.onHiddenFilterChange('rating', [rating]); }}})],1):_vm._e()]],2),(_vm.isLoading)?_c('AtomLoading',{attrs:{"floating":"","inline":""}}):_vm._e(),(_vm.feedbackDetails)?_c('FeedbackDetailsModal',{attrs:{"business-id":_vm.feedbackDetails.businessId,"rating":_vm.feedbackDetails.rating,"comment":_vm.feedbackDetails.comment,"location-id":_vm.feedbackDetails.premiseId,"created-at":_vm.feedbackDetails.createdAt,"customer-name":_vm.feedbackDetails.customer.name,"customer-phone-number":_vm.feedbackDetails.customer.phoneNumber,"order-id":_vm.feedbackDetails.id,"is-pat":_vm.feedbackDetails.product.type === _vm.ProductTypeEnum.PAT,"product-type":_vm.getProductTypeLabel(_vm.feedbackDetails.product.type),"product-id":_vm.feedbackDetails.product.id,"on-close":_vm.handleClose,"open-order":_vm.showOrder}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }