import { AxiosResponse } from "axios";
import { IRepoErrors } from "../../errors";
import { http } from "../../http";

export type IFetchBill = {
  id: string;
  posBillId: string;
  tableId: string;
  locationId: string;
  paidAt: string;
  status: string;
};

export type IFetchBillResponse = {
  id: string;
  pos_bill_id: string;
  table_id: string;
  location_id: string;
  paid_at: string;
  status: string;
};
export class FetchBillError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchTablePayments");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchBill({
  businessId,
  billId,
}: {
  businessId: string;
  billId: string;
}): Promise<IFetchBill> {
  let response: AxiosResponse;

  try {
    response = await http.get(
      `/pat/businesses/${businessId}/get-bill-by-id/${billId}`
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchBillError(error);
  }

  const result: IFetchBillResponse = response?.data || {};
  return {
    id: result.id,
    posBillId: result.pos_bill_id,
    tableId: result.table_id,
    locationId: result.location_id,
    paidAt: result.paid_at,
    status: result.status,
  };
}
