import { AxiosResponse } from "axios";
import { IRepoErrors } from "../../errors";
import { http } from "../../http";
import { ProductTypeEnum } from "@/v2/repo/reviews";

interface IFetchReviewsRequest {
  businessId: string;
  page: number;
  itemsPerPage: number;
  rating?: Array<string>;
  onlyWithComments?: boolean;
  productType?: `${ProductTypeEnum}`;
}

interface IReviewsParams {
  page: number;
  per_page: number;
  rating?: Array<string> | undefined;
  only_with_comments?: number | undefined;
  product_type?: `${ProductTypeEnum}` | undefined;
}
type IDataResponse = {
  id: string;
  business_id: string;
  rating: number;
  created_at: string;
  premise_id: string;
  comment: string;
  product: {
    type: `${ProductTypeEnum}`;
    id: string;
  };
  customer: {
    name: string | null;
    phone_number: string | null;
  };
};

export type IReview = {
  id: string;
  businessId: string;
  rating: number;
  createdAt: Date;
  premiseId: string;
  comment: string;
  product: {
    type: `${ProductTypeEnum}`;
    id: string;
  };
  customer: {
    name: string;
    phoneNumber: string | null;
  };
};

export interface IFetchReviewsResponse {
  data: Array<IReview>;
  paginator: {
    total: number;
    perPage: number;
    currentPage: number;
    lastPage: number;
  };
}

export class FetchReviewsError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchReviews");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchReviews(
  request: IFetchReviewsRequest
): Promise<IFetchReviewsResponse> {
  let response: AxiosResponse;

  let params: IReviewsParams = {
    page: request.page,
    per_page: request.itemsPerPage,
    rating: request.rating,
    product_type: request.productType,
  };

  if ("onlyWithComments" in request) {
    params = {
      ...params,
      only_with_comments: request.onlyWithComments ? 1 : 0,
    };
  }

  try {
    response = await http.get(
      `/feedback/list-by-business/${request.businessId}`,
      {
        params,
      }
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchReviewsError(error);
  }

  return {
    data: response.data?.data.map((obj: IDataResponse) => ({
      id: obj.id,
      customer: {
        name: obj.customer.name,
        phoneNumber: obj.customer.phone_number,
      },
      businessId: obj.business_id,
      product: {
        type: obj.product.type,
        id: obj.product.id,
      },
      rating: obj.rating,
      createdAt: new Date(obj.created_at),
      premiseId: obj.premise_id,
      comment: obj.comment,
    })),
    paginator: {
      total: response.data?.meta.total,
      perPage: response.data?.meta.per_page,
      currentPage: response.data?.meta.current_page,
      lastPage: response.data?.meta.last_page,
    },
  };
}
