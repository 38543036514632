import { http } from "../../http";
import { AxiosResponse } from "axios";
import { IRepoErrors } from "@/v2/repo/errors";
import { ProductTypeEnum } from "@/v2/repo/reviews";

interface IRatingStatResponse {
  rating_value: string;
  count: number;
}

export interface IRatingStat {
  ratingValue: string;
  count: number;
}

interface IParams {
  rating?: Array<string>;
  onlyWithComments?: boolean;
  productType?: `${ProductTypeEnum}`;
}

export class FetchRatingStatsError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchRatingStats");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchRatingStats(
  businessId: string,
  params: IParams = {}
): Promise<Array<IRatingStat>> {
  let response: AxiosResponse;

  let onlyWithComments;
  if ("onlyWithComments" in params) {
    onlyWithComments = params.onlyWithComments ? 1 : 0;
  }

  try {
    response = await http.get(
      `/feedback/rating-stats-of-business/${businessId}`,
      {
        params: {
          rating: params.rating,
          product_type: params.productType,
          only_with_comments: onlyWithComments,
        },
      }
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchRatingStatsError(error);
  }

  return response.data?.rating_stats.map(
    (item: IRatingStatResponse): IRatingStat => ({
      count: item.count,
      ratingValue: item.rating_value,
    })
  );
}
