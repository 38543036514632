

























































































































import { t } from "@/i18n";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  PropType,
  computed,
  onMounted,
  ref,
} from "@vue/composition-api";
import dayjs from "dayjs";
import {
  AtomButton,
  AtomModal,
  AtomText,
  AtomTextTypeEnum,
  AtomButtonTypeEnum,
  AtomTextColorEnum,
  AtomLoading,
  MolRating,
} from "@/v2/new-design-system";
import { fetchOutletById, FetchOutletByIdError } from "@/v2/repo/reviews";
import { report } from "@chatfood/bug-reporter";
import { fetchBill } from "@/v2/repo/table-payment/fetch-bill";
// @ts-ignore
import Orders from "@/api/orders";

const css = bemBuilder("feedback-details-modal");

export default defineComponent({
  name: "FeedbackDetailsModal",
  components: {
    AtomButton,
    AtomModal,
    AtomText,
    AtomLoading,
    MolRating,
  },
  props: {
    rating: {
      type: Number,
      required: true,
    },
    businessId: {
      type: String,
      required: true,
    },
    productType: {
      type: String,
      required: true,
    },
    isPat: {
      type: Boolean,
      default: false,
    },
    comment: {
      type: String,
      default: null,
    },
    customerName: {
      type: String,
      required: true,
    },
    customerPhoneNumber: {
      type: String,
      default: null,
    },
    createdAt: {
      type: Date,
      required: true,
    },
    locationId: {
      type: String,
      required: true,
    },
    productId: {
      type: String,
      required: true,
    },
    onClose: {
      type: Function as PropType<() => void>,
      required: true,
    },
    openOrder: {
      type: Function as PropType<(orderId: string) => void>,
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref(false);
    const locationName = ref();
    const orderId = ref();

    onMounted(async () => {
      try {
        isLoading.value = true;
        await Promise.all([getLocationName(), getOrderId()]);
      } finally {
        isLoading.value = false;
      }
    });

    const getOrderId = async () => {
      if (props.isPat) {
        try {
          const response = await fetchBill({
            businessId: props.businessId,
            billId: props.productId,
          });
          orderId.value = response.posBillId;
        } catch (e) {
          report(e);
        }
      } else {
        try {
          const response = await Orders.find(props.productId);
          orderId.value = response.data.uid;
        } catch (e) {
          report(e);
        }
      }
    };

    const getLocationName = async () => {
      try {
        const { name } = await fetchOutletById(props.locationId);
        locationName.value = name;
      } catch (e) {
        if (
          e instanceof FetchOutletByIdError &&
          e.errors.errorCode === "access_forbidden"
        ) {
          return;
        }

        report(e);
      }
    };

    const ratingList = computed(() => {
      return [...Array(5).keys()].reduce(
        (res: Array<number>, i): Array<number> => [i + 1, ...res],
        []
      );
    });

    const idLabel = computed(() => {
      return props.isPat
        ? t("module.reviews.feedback_details_modal.bill_id")
        : t("module.reviews.feedback_details_modal.order_id");
    });

    const userName = computed(() =>
      props.customerName === "anonymous"
        ? t("module.reviews.feedback_details_modal.anonymous")
        : props.customerName
    );

    const reviewDate = computed(() => {
      return dayjs(props.createdAt).format("D MMMM YYYY  h:mm A");
    });

    const onClick = () => {
      props.openOrder(props.productId);
    };

    return {
      t,
      css,
      ratingList,
      idLabel,
      userName,
      locationName,
      orderId,
      reviewDate,
      isLoading,
      onClick,
      AtomButtonTypeEnum,
      AtomTextColorEnum,
      AtomTextTypeEnum,
    };
  },
});
