






























































































































import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
} from "@vue/composition-api";
import { t } from "@/i18n";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomLoading,
  AtomButton,
  MolFeedbackCard,
  MolPagination,
  OrgHeaderInfo,
  OrgRatingAverage,
} from "@/v2/new-design-system";
import {
  fetchRatingStats,
  fetchReviews,
  IFetchReviewsResponse,
  IRatingStat,
  IReview,
} from "@/v2/repo/reviews";
import { report } from "@chatfood/bug-reporter";
import { FeedbackDetailsModal } from "../feedback-details-modal";
import { ProductTypeEnum } from "@/v2/repo/reviews/fetch-feedbacks/product-type.enum";
import Order from "@/modules/order-card/Index.vue";
import { reviewsTrack } from "../track";
import { EventEnum } from "@/v2/enum";
import { ReviewsFilters } from "@/v2/module/reviews/filters";
const css = bemBuilder("reviews");

type IFilterState = {
  rating: string[];
  productType: ProductTypeEnum[];
  onlyWithComments?: boolean;
};

export default defineComponent({
  name: "ReviewsMain",
  components: {
    AtomText,
    AtomLoading,
    AtomButton,
    MolFeedbackCard,
    MolPagination,
    OrgHeaderInfo,
    OrgRatingAverage,
    FeedbackDetailsModal,
    ReviewsFilters,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref(false);
    const isLoadingList = ref(false);
    const isFilterLoading = ref(false);
    const showFilters = ref(true);
    const reviewList = ref<Array<IReview>>([]);
    const paginationInfo = ref<IFetchReviewsResponse["paginator"]>();
    const feedbackDetails = ref<IReview | null>(null);
    const ratingInfo = ref<Array<IRatingStat>>([]);
    const filterState = ref<IFilterState>({ rating: [], productType: [] });
    const reviewsCounter = computed(
      () =>
        ratingInfo.value.reduce((result, item) => result + item.count, 0) || 0
    );

    const isFiltersActive = computed(() => {
      const { rating, productType, onlyWithComments } = filterState.value;
      return (
        Boolean(rating.length) ||
        Boolean(productType.length) ||
        onlyWithComments
      );
    });

    const isRatingFilterActive = computed(() => {
      const { rating } = filterState.value;
      return Boolean(rating.length);
    });

    const fetchFeedbackList = async (
      page: number,
      filters: Record<string, any> = {}
    ) => {
      try {
        isLoadingList.value = true;
        const result = await fetchReviews({
          businessId: props.businessId,
          itemsPerPage: 10,
          page,
          ...filters,
        });
        reviewList.value = result.data;
        paginationInfo.value = result.paginator;
      } catch (e) {
        report(e);
      } finally {
        isLoadingList.value = false;
      }
    };
    const fetchAverageInfo = async (filters: Record<string, any> = {}) => {
      try {
        const result = await fetchRatingStats(props.businessId, filters);
        ratingInfo.value = ["1", "2", "3", "4", "5"].reduce(
          (res: IRatingStat[], item: string): IRatingStat[] => {
            const { count } = result.find((el) => el.ratingValue == item) || {};
            return [...res, { count: count || 0, ratingValue: item }];
          },
          []
        );
      } catch (e) {
        report(e);
      }
    };

    const openDetails = (details: IReview): void => {
      track(EventEnum.REVIEWS_VIEW_DETAILS, {
        transaction_reference: details.product.id,
      });
      feedbackDetails.value = details;
    };

    const handleClose = (): void => {
      feedbackDetails.value = null;
    };

    const getProductTypeLabel = (productType: ProductTypeEnum): string => {
      const typeMap = {
        [ProductTypeEnum.PAT]: t("module.reviews.service_pat"),
        [ProductTypeEnum.OAT]: t("module.reviews.service_oat"),
        [ProductTypeEnum.DINE_IN]: t("module.reviews.service_dine-in"),
        [ProductTypeEnum.PICKUP]: t("module.reviews.service_pickup"),
        [ProductTypeEnum.DELIVERY]: t("module.reviews.service_delivery"),
        [ProductTypeEnum.PAYMENT_LINK]: t(
          "module.reviews.service_payment-link"
        ),
      };

      return typeMap[productType];
    };

    const vm = getCurrentInstance();

    const showOrder = (orderId: string) => {
      handleClose();

      vm?.proxy.$modal.show(
        Order,
        { orderId, businessId: props.businessId },
        {
          name: "order",
          height: "auto",
          width: "100%",
          maxWidth: 600,
          adaptive: true,
          "pivot-y": 0.15,
        }
      );
    };

    function track(
      eventName: EventEnum,
      additionalData: Record<string, string> = {}
    ) {
      reviewsTrack({
        // @ts-ignore
        analytics: vm?.proxy.$analytics,
        businessId: props.businessId,
        eventName,
        additionalData,
      });
    }

    const filterModifier = (
      filters: IFilterState | {} = {}
    ): IFilterState | {} => {
      let modifiedFilter = { ...filters };

      if (modifiedFilter.productType?.length) {
        const productType = modifiedFilter.productType.reduce(
          (result: ProductTypeEnum[], type: ProductTypeEnum) => {
            if (type === ProductTypeEnum.OAT)
              return [...result, ProductTypeEnum.OAT, ProductTypeEnum.DINE_IN];

            return [...result, type];
          },
          []
        );
        modifiedFilter = { ...modifiedFilter, productType };
      }
      return modifiedFilter;
    };

    async function fetchReviewsData(
      firstLoading: boolean,
      filters: IFilterState | {} = {}
    ): Promise<void> {
      try {
        if (firstLoading) isLoading.value = true;
        else isFilterLoading.value = true;

        const modifiedFilter = filterModifier(filters);
        await Promise.all([
          fetchFeedbackList(0, modifiedFilter),
          fetchAverageInfo(modifiedFilter),
        ]);
      } finally {
        isLoading.value = false;
        isFilterLoading.value = false;
      }
    }

    onMounted(async () => {
      track(EventEnum.REVIEWS_VIEW_PAGE);
      await fetchReviewsData(true);
      showFilters.value = Boolean(reviewList.value.length);
    });

    /* Set filters by clicking on hidden buttons, like product type label and rating icons */
    const onHiddenFilterChange = (
      key: string,
      value: ProductTypeEnum | string[]
    ) => {
      if (!showFilters.value) return;

      filterState.value = {
        ...filterState.value,
        [key]: value,
      };
      fetchReviewsData(false, filterState.value);
    };

    const onFilterChange = (filters: IFilterState) => {
      filterState.value = filters;
      fetchReviewsData(false, filters);
    };

    const onFilterReset = () => {
      filterState.value = { productType: [], rating: [] };
      fetchReviewsData(false);
    };

    const onPaginationChanged = (page: number) => {
      const modifiedFilter = filterModifier(filterState.value);
      fetchFeedbackList(page, modifiedFilter);
    };

    return {
      t,
      css,
      isLoading,
      isLoadingList,
      isFilterLoading,
      reviewList,
      showFilters,
      paginationInfo,
      ratingInfo,
      reviewsCounter,
      feedbackDetails,
      filterState,
      isFiltersActive,
      isRatingFilterActive,
      onHiddenFilterChange,
      onFilterReset,
      onFilterChange,
      onPaginationChanged,
      fetchFeedbackList,
      openDetails,
      handleClose,
      getProductTypeLabel,
      showOrder,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      ProductTypeEnum,
    };
  },
});
