




































import { t } from "@/i18n";
import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
} from "@vue/composition-api";
import { bemBuilder } from "@chatfood/core-utils";
import {
  AtomText,
  AtomButton,
  AtomButtonTypeEnum,
  AtomSwitcher,
  MolMultiselect,
} from "@/v2/new-design-system";
import { ProductTypeEnum } from "@/v2/repo/reviews";

const css = bemBuilder("reviews-filters");

export default defineComponent({
  name: "ReviewsFilters",
  components: {
    AtomText,
    AtomButton,
    AtomSwitcher,
    MolMultiselect,
  },
  props: {
    rating: {
      type: Array as PropType<string[]>,
      required: true,
    },
    productType: {
      type: Array as PropType<ProductTypeEnum[]>,
      required: true,
    },
    onChange: {
      type: Function as PropType<
        (filters: {
          rating: string[];
          productType: string[];
          onlyWithComments: Boolean;
        }) => void
      >,
      default: () => {},
    },
    onReset: {
      type: Function as PropType<() => {}>,
      default: () => {},
    },
  },
  setup(props) {
    const ratingFilterItems = ref([
      { id: "", name: t("module.reviews.rating_filter.all") },
      { id: "1", name: t("module.reviews.rating_filter.1_star") },
      { id: "2", name: t("module.reviews.rating_filter.2_stars") },
      { id: "3", name: t("module.reviews.rating_filter.3_stars") },
      { id: "4", name: t("module.reviews.rating_filter.4_stars") },
      { id: "5", name: t("module.reviews.rating_filter.5_stars") },
      { id: "4,5", name: t("module.reviews.rating_filter.positive") },
      { id: "1,2,3", name: t("module.reviews.rating_filter.critical") },
    ]);
    const ratingFilter = ref();
    const productTypeFilterItems = ref([
      { id: "", name: t("module.reviews.product_type_filter.all") },
      { id: "pat", name: t("module.reviews.product_type_filter.pat") },
      { id: "oat", name: t("module.reviews.product_type_filter.oat") },
      { id: "pickup", name: t("module.reviews.product_type_filter.pickup") },
      {
        id: "delivery",
        name: t("module.reviews.product_type_filter.delivery"),
      },
    ]);
    const productTypeFilter = ref(
      getDefaultFilterItem(props.productType, productTypeFilterItems.value)
    );
    const commentFilter = ref(false);

    watch(
      () => props.rating,
      (value) => {
        ratingFilter.value = getDefaultFilterItem(
          value,
          ratingFilterItems.value
        );
      },
      { immediate: true }
    );

    watch(
      () => props.productType,
      (value) => {
        productTypeFilter.value = getDefaultFilterItem(
          value,
          productTypeFilterItems.value
        );
      },
      { immediate: true }
    );

    const showResetButton = computed(() => {
      const [product] = productTypeFilter.value;
      const [rating] = ratingFilter.value;
      return Boolean(commentFilter.value || product?.id || rating?.id);
    });

    function getDefaultFilterItem(
      defaultValue: string[],
      list: { id: string; name: string }[]
    ): { id: string; name: string }[] {
      let existingItem;
      if (defaultValue?.length) {
        existingItem = list.filter(({ id }) => defaultValue.join(",") === id);
      }
      return existingItem || list.slice(0, 1);
    }

    function onFiltersChange() {
      props.onChange({
        rating: prepareFilters(ratingFilter.value),
        productType: prepareFilters(productTypeFilter.value),
        onlyWithComments: commentFilter.value,
      });
    }

    const prepareFilters = (list: Array<{ id: string; name: string }>) =>
      list.reduce(
        (result, { id }) => (id ? [...result, ...id.split(",")] : result),
        [] as Array<string>
      );

    const setRatingFilter = (value: string): void => {
      ratingFilter.value = ratingFilterItems.value.filter(
        ({ id }) => value === id
      );
      onFiltersChange();
    };

    const setProductFilter = (value: ProductTypeEnum): void => {
      const selectedValue =
        value === ProductTypeEnum.DINE_IN ? ProductTypeEnum.OAT : value;

      productTypeFilter.value = productTypeFilterItems.value.filter(
        ({ id }) => selectedValue === id
      );
      onFiltersChange();
    };

    const setCommentFilter = (value: boolean): void => {
      commentFilter.value = value;
      onFiltersChange();
    };

    const onResetFilters = (): void => {
      props.onReset();
      commentFilter.value = false;
      ratingFilter.value = getDefaultFilterItem(
        props.rating,
        ratingFilterItems.value
      );
      productTypeFilter.value = getDefaultFilterItem(
        props.productType,
        productTypeFilterItems.value
      );
    };

    return {
      t,
      css,
      showResetButton,
      ratingFilter,
      ratingFilterItems,
      productTypeFilter,
      productTypeFilterItems,
      commentFilter,
      setRatingFilter,
      setCommentFilter,
      setProductFilter,
      onResetFilters,
      AtomButtonTypeEnum,
    };
  },
});
