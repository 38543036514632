import { IRepoErrors } from "../../errors";
import { http } from "../../http";
import { AxiosError } from "axios";

export class FetchOutletByIdError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: fetchOutletById");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export type IOutlet = { id: string; name: string };

export async function fetchOutletById(outlet: string): Promise<IOutlet> {
  try {
    const response = await http.get(`api/v1/get-outlet-by-id/${outlet}`);
    return response.data;
  } catch (e: any) {
    if (!(e instanceof AxiosError)) {
      throw e;
    }

    const error: IRepoErrors<null> = {};

    if (e.response?.status === 403) {
      error.errorCode = "access_forbidden";
    }

    throw new FetchOutletByIdError(error);
  }
}
